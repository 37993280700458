* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#isbnScroller {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.librariesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.bold {
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.book {
  width: 12rem;
  padding: 1rem;
  display: flex;
  text-align: center;
  align-items: center;
  word-wrap: break-word;
  justify-content: space-between;
  flex-direction: column;
}

.bookImg {
  width: 8rem;
}

.headerImg {
  width: 4rem;
}

#header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightblue;
  height: 4rem;
}

#header a {
  margin: 10px;
}

#headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
}

button {
  border: solid 1px gray;
  background-color: #e3f3ff;
  padding: 5px 10px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 8px;
}

.alertButton {
  background-color: #ffd0d8;
  padding: 3px 5px;
}

.topbar {
  width: 100%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.about {
  width: 100%;
  position: absolute;
  top: 0;
  padding-right: 2rem;
  display: flex;
  justify-content: flex-end;
}

a {
  color: gray;
}

.isbnSearchBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Library styles*/
.library {
  display: flex;
}

.libraryTop {
  display: flex;
  align-items: center;
}

.libraryName {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  list-style-type: none;
  text-align: center;
  padding-left: 0;
}

li {
  margin: 0.5rem;
}

/* class applies to select element itself, not a wrapper element */
.select-css {
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 500;
  color: #444;
  line-height: 1;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 5px;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

*/
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}

@media all and (max-width: 800px) {
  #header {
    align-items: center;
    height: auto;
    padding: 1%;
  }

  #header img,
  h1,
  a {
    display: none;
  }

  .bottombar {
    display: none;
  }
}
